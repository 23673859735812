#card-title-extra {
  font-weight: bold;
}

.align-start {
  text-align: start;
}

.list-group-item {
  background-color: #212529 !important;
  color: white !important;
}

.list-style {
  padding: 0;
}

.list-style li {
  list-style-type: none;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid hsla(0, 0%, 98%, 0.12);
}
