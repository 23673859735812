.fw-600 {
  font-weight: 600;
}

.backText {
  font-size: calc(1.95rem + 8.4vw);
}

@media (min-width: 1200px) {
  .backText {
    font-size: 8rem;
  }
}

.mainText {
  font-size: 2.5rem;
}

.bottomUnderline {
  border: 2px solid;
  border-color: #e04c4c;
  border-width: 3px;
  width: 80px;
}

.opaqueText {
  opacity: 0.1;
}
