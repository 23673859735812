div {
  text-align: center;
}

.home-background {
  background: "monitors-unsplash.jpg";
  background-image: linear-gradient(rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0.5)),
    url("/public/images/monitors-unsplash.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  z-index: 0;
}

.Content {
  height: 100vh;
  position: relative;
}

.biggerHeader {
  font-size: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.downArrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
}

.expandMoreIcon:hover {
  transform: scale(2);
}

.expandMoreIcon {
  font-size: 3.5rem !important;
  filter: drop-shadow(0px 0px 10px rgb(255, 255, 255));
  animation: bounce 0.7s infinite alternate;
}

@keyframes bounce {
  to {
    transform: scale(1.5);
  }
}
